<fieldset *ngIf="form">
    <form-floating>
        <label i18n="@@addressDetails_country_label" app-i18n="addressDetails_country_label">Land *</label>
        <select [formControl]="form.get('country')" (change)="addressFromPostal()" class="form-select" data-cy="country" input-validation>
            <option value="nl" i18n="@@addressDetails_country_netherlands" app-i18n="addressDetails_country_netherlands">Nederland</option>
            <option value="be" i18n="@@addressDetails_country_belgium" app-i18n="addressDetails_country_belgium">België</option>
            <option value="lu" i18n="@@addressDetails_country_luxembourg" app-i18n="addressDetails_country_luxembourg">Luxemburg</option>
            <option value="de" i18n="@@addressDetails_country_germany" app-i18n="addressDetails_country_germany">Duitsland</option>
            <option value="dk" i18n="@@addressDetails_country_denmark" app-i18n="addressDetails_country_denmark">Denemarken</option>
            <option value="uk" i18n="@@addressDetails_country_unitedKingdom" app-i18n="addressDetails_country_unitedKingdom">
                Verenigd Koninkrijk
            </option>
            <option value="fr" i18n="@@addressDetails_country_france" app-i18n="addressDetails_country_france">Frankrijk</option>
            <option value="es" i18n="@@addressDetails_country_spain" app-i18n="addressDetails_country_spain">Spanje</option>
            <option value="it" i18n="@@addressDetails_country_italy" app-i18n="addressDetails_country_italy">Italië</option>
            <option value="se" i18n="@@addressDetails_country_sweden" app-i18n="addressDetails_country_sweden">Zweden</option>
            <option value="pt" i18n="@@addressDetails_country_portugal" app-i18n="addressDetails_country_portugal">Portugal</option>
        </select>
    </form-floating>

    <div class="row">
        <div class="col-12 col-sm-6">
            <form-floating>
                <label i18n="@@addressDetails_postal_label" app-i18n="addressDetails_postal_label">Postcode *</label>
                <input
                    [formControl]="form.get('postal')"
                    type="text"
                    class="form-control"
                    placeholder="Postcode"
                    i18n-placeholder="@@addressDetails_postal_place"
                    [attr.placeholder]="i18n('addressDetails_postal_place')"
                    (blur)="addressFromPostal()"
                    data-cy="postal"
                    input-validation
                />

                <ng-container *ngIf="form.get('city').disabled">
                    <button *ngIf="postalLoading" class="btn btn-outline-secondary">
                        <fa-icon [icon]="['fal', 'spinner-third']" [fixedWidth]="true" [spin]="true"></fa-icon>
                    </button>
                    <button
                        *ngIf="!postalLoading"
                        class="btn btn-outline-info"
                        (click)="postalUserDontKnow(); $event.stopPropagation()"
                        tippy
                        i18n-data-tippyOptionsContent="@@addressDetails_postal_unknown"
                        data-tippyOptionsContent="Ik weet mijn postcode niet"
                        [tippyOptions]="{ content: i18n('addressDetails_postal_unknown') }"
                    >
                        <fa-icon [icon]="['fal', 'question-circle']" [fixedWidth]="true"></fa-icon>
                    </button>
                </ng-container>
            </form-floating>
        </div>
        <div class="col-12 col-sm-3">
            <form-floating>
                <label i18n="@@addressDetails_streetNumber_label" app-i18n="addressDetails_streetNumber_label">Huisnummer *</label>
                <input
                    [formControl]="form.get('number')"
                    class="form-control"
                    type="text"
                    placeholder="Nummer"
                    i18n-placeholder="@@addressDetails_streetNumber_place"
                    [attr.placeholder]="i18n('addressDetails_streetNumber_place')"
                    (blur)="addressFromPostal()"
                    data-cy="number"
                    input-validation
                />
            </form-floating>
        </div>
        <div class="col-12 col-sm-3">
            <form-floating>
                <label i18="@@addressDetails_streetAddition_place" app-i18n="addressDetails_streetAddition_place">Toevoeging</label>
                <input
                    [formControl]="form.get('addition')"
                    class="form-control"
                    type="text"
                    placeholder="Toevoeging"
                    i18n-placeholder="@@addressDetails_streetAddition_place"
                    [attr.placeholder]="i18n('addressDetails_streetAddition_place')"
                    data-cy="addition"
                    input-validation
                />
            </form-floating>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-6">
            <form-floating>
                <label i18n="@@addressDetails_city_label" app-i18n="addressDetails_city_label">Stad *</label>
                <input
                    [formControl]="form.get('city')"
                    class="form-control"
                    type="text"
                    placeholder="Stad"
                    i18n-placeholder="@@addressDetails_city_place"
                    [attr.placeholder]="i18n('addressDetails_city_place')"
                    data-cy="city"
                    input-validation
                />
            </form-floating>
        </div>
        <div class="col-12 col-sm-6">
            <form-floating>
                <label i18n="@@addressDetails_streetName_label" app-i18n="addressDetails_streetName_label">Straatnaam *</label>
                <input
                    [formControl]="form.get('street')"
                    class="form-control"
                    type="text"
                    placeholder="Straatnaam"
                    i18n-placeholder="@@addressDetails_streetName_place"
                    [attr.placeholder]="i18n('addressDetails_streetName_place')"
                    data-cy="street"
                    input-validation
                />
            </form-floating>
        </div>
    </div>

    <div class="form-group row" *ngIf="message.notFound">
        <div class="col-12">
            <div class="alert alert-warning">
                <h5 class="alert-heading" i18n="@@addressDetails_postal_error_title" app-i18n="addressDetails_postal_error_title">
                    Postcode onbekend
                </h5>
                <p class="mb-0" i18n="@@addressDetails_postal_error_body" app-i18n="addressDetails_postal_error_body">
                    Wij hebben de postcode niet kunnen vinden of je hebt aangegeven de postcode niet te weten
                </p>
            </div>
        </div>
    </div>

    <ng-container *ngIf="hasRemovalInfo">
        <h5 class="card-title mt-2" i18n="@@addressDetails_label_info" app-i18n="addressDetails_label_info">Verhuisinformatie</h5>

        <form-floating>
            <label i18n="@@addressDetails_floor_label" app-i18n="addressDetails_floor_label">Verdieping</label>
            <select
                [formControl]="form.get('floor')"
                class="form-select"
                data-cy="floor"
                (input)="form.get('moveType').patchValue('stairs')"
                input-validation
            >
                <option [ngValue]="null" selected disabled i18n="@@addressDetails_floor_select" app-i18n="addressDetails_floor_select">
                    Kies een etage
                </option>
                <option *ngFor="let option of optionsFloor" [ngValue]="option.value">{{ option.label }}</option>
            </select>
        </form-floating>

        <div class="mb-3" *ngIf="form.value.floor > 0">
            <div class="form-check form-switch">
                <input
                    [formControl]="form.get('liftInternal')"
                    type="checkbox"
                    class="form-check-input"
                    id="liftInternal"
                    (change)="setMoveType()"
                />
                <div class="input-group input-group-sm d-flex align-items-center">
                    <label
                        class="form-check-label text-muted"
                        for="liftInternal"
                        data-cy="liftInternal"
                        i18n="@@addressDetails_moveMethod_question_liftInternal"
                        app-i18n="addressDetails_moveMethod_question_liftInternal"
                    >
                        Interne lift aanwezig?
                    </label>
                    <span class="ms-2">
                        <information-icon [className]="'text-info'" [size]="'sm'" (click)="$event.stopPropagation()">
                            <h3
                                header
                                i18n="@@addressDetails_moveMethod_infoHeader_liftInternal"
                                app-i18n="addressDetails_moveMethod_infoHeader_liftInternal"
                            >
                                Geen interne lift?
                            </h3>
                            <span
                                body
                                i18n="@@addressDetails_moveMethod_infoBody_liftInternal"
                                app-i18n="addressDetails_moveMethod_infoBody_liftInternal"
                                >Indien er geen interne lift aanwezig is en je geen verhuislift selecteert in stap 4, zullen wij je
                                verhuizing uitvoeren via de trap.</span
                            >
                        </information-icon>
                    </span>
                </div>
            </div>
        </div>

        <form-floating>
            <label i18n="@@addressDetails_workDescription_label" app-i18n="addressDetails_workDescription_label"
                >Omschrijving werkzaamheden (optioneel)</label
            >
            <textarea
                class="form-control"
                [style.height]="'200px'"
                [formControl]="form.get('description')"
                placeholder="Hier kun je extra informatie kwijt over wat er moet gebeuren op dit adres"
                i18n-placeholder="@@addressDetails_workDescription_place"
                [attr.placeholder]="i18n('addressDetails_workDescription_place')"
                input-validation
                autocomplete="off"
            ></textarea>
            <div help-text i18n="@@addressDetails_workDescription_helpText" app-i18n="addressDetails_workDescription_helpText">
                Hier kun je extra informatie kwijt over wat er moet gebeuren op dit adres
            </div>
        </form-floating>
    </ng-container>
</fieldset>
