import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { GoogleAnalyticsService } from 'app/_services/google-analytics.service'
import { ProgressbarModule } from 'ngx-bootstrap/progressbar'
import { NgFor, NgIf } from '@angular/common'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import { I18nService } from 'app/_services/i18n.service'
import { I18nDirective } from '../directives/app-i18n.directive'

@Component({
    selector: 'stepper',
    templateUrl: './stepper.component.html',
    standalone: true,
    imports: [NgFor, NgIf, ProgressbarModule, I18nDirective],
})
export class StepperComponent implements OnInit {
    @ViewChild('stepContainer', { static: true }) stepContainer: ElementRef

    @Input() stepArray
    @Input() form
    /** Pointer at the right language section */
    @Input({ required: true }) section: string
    @Input() orderwork
    @Input() validateStep
    @Input() shouldTriggerGTM = false
    @Output() stepChange = new EventEmitter()
    stepActive
    get stepIndex() {
        return this.stepArray.indexOf(this.stepActive)
    }
    get progress() {
        if (this.stepIndex === this.stepArray.length - 1) return 100
        return 100 * ((this.stepIndex + 1) / this.stepArray.length) - 0.5 * (100 / this.stepArray.length)
    }

    constructor(private googleAnalyticsService: GoogleAnalyticsService, private i18nService: I18nService) {}

    ngOnInit() {
        const activeStep = this.orderwork.stepActive || this.stepArray[0]
        const stepIndex = this.stepArray.indexOf(activeStep)
        this.goToStep(stepIndex)
    }

    step(num) {
        const nextStepIndex = this.stepIndex + num
        this.goToStep(nextStepIndex)
    }

    goToStep(index) {
        if (this.validateStep && index > this.stepIndex) {
            const isValid = this.validateStep()
            if (!isValid) return this.scrollTop()
        }
        const step = this.stepArray[index]
        const stepHistory = this.form.value.stepHistory
        if (this.shouldTriggerGTM && !stepHistory.includes(step)) {
            console.log('We are sending to GTM', step)
            this.googleAnalyticsService.gtmEmit(step, this.orderwork.identifier, this.i18nService.languageId)
        }
        this.form.patchValue({
            stepActive: step,
            stepHistory: [...stepHistory, this.stepActive],
        })
        this.stepActive = step
        this.stepChange.emit(step)
        this.scrollTop()
    }

    scrollTop() {
        scrollIntoView(this.stepContainer.nativeElement)
    }

    isStepDisabled(step) {
        if (!this.form) return
        const stepHistory = this.form.value.stepHistory
        if (step === this.stepActive) return false
        return !stepHistory.includes(step)
    }
    tryStep(step) {
        const stepHistory = this.form.value.stepHistory
        if (!stepHistory.includes(step)) return
        const stepIndex = this.stepArray.indexOf(step)
        this.goToStep(stepIndex)
    }
}
