import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { I18nService } from 'app/_services/i18n.service'
import { Subscription } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

@Directive({
    selector: '[app-i18n]',
    standalone: true,
})
export class I18nDirective implements OnInit, OnChanges, OnDestroy {
    /** Name of the language key */
    @Input('app-i18n') public key: string

    constructor(private el: ElementRef, private languageService: I18nService) {
        this.el = el
    }

    private languageSubscription: Subscription

    ngOnInit() {
        if (this.languageService.ready) this.applyExtension()
        this.languageSubscription = this.languageService.messages
            .pipe(
                filter((v) => v !== null),
                tap(() => this.applyExtension())
            )
            .subscribe()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['key']) this.applyExtension()
    }

    private applyExtension() {
        const translation = this.languageService.getMessage(this.key)

        if (translation !== undefined && translation !== null) this.el.nativeElement.innerHTML = translation
    }

    ngOnDestroy() {
        this.languageSubscription.unsubscribe()
    }
}
