<ng-container *ngIf="form">
    <form-email *ngIf="hasEmail" [form]="form.get('email.0.value')"></form-email>
    <div class="row">
        <div class="col-12 col-sm-4">
            <form-floating>
                <input
                    class="form-control"
                    [formControl]="form.get('name.first')"
                    type="text"
                    placeholder="Voornaam"
                    i18n-placeholder="@@contactDetails_firstName_place"
                    [attr.placeholder]="i18n('contactDetails_firstName_place')"
                    data-cy="nameFirst"
                    autocomplete="given-name"
                    input-validation
                />
                <label i18n="@@contactDetails_firstName_label" app-i18n="contactDetails_firstName_label"></label>
            </form-floating>
        </div>
        <div class="col-12 col-sm-4">
            <form-floating>
                <label i18n="@@contactDetails_middleName_label" app-i18n="contactDetails_middleName_label"></label>
                <input
                    class="form-control"
                    [formControl]="form.get('name.middle')"
                    type="text"
                    placeholder="Tussenvoegsel"
                    i18n-placeholder="@@contactDetails_middleName_place"
                    [attr.placeholder]="i18n('contactDetails_middleName_place')"
                    data-cy="nameMiddle"
                    autocomplete="additional-name"
                    input-validation
                />
            </form-floating>
        </div>
        <div class="col-12 col-sm-4">
            <form-floating>
                <label i18n="@@contactDetails_lastName_label" app-i18n="contactDetails_lastName_label"></label>
                <input
                    class="form-control"
                    [formControl]="form.get('name.last')"
                    type="text"
                    placeholder="Achternaam"
                    i18n-placeholder="@@contactDetails_lastName_place"
                    [attr.placeholder]="i18n('contactDetails_lastName_place')"
                    data-cy="nameLast"
                    autocomplete="family-name"
                    input-validation
                />
            </form-floating>
        </div>
    </div>

    <form-floating>
        <label i18n="@@contactDetails_phoneNumber_label" app-i18n="contactDetails_phoneNumber_label"></label>
        <input
            [formControl]="form.get('mobile.0.value')"
            class="form-control"
            type="tel"
            placeholder="Telefoonnummer"
            i18n-placeholder="@@contactDetails_phoneNumber_place"
            [attr.placeholder]="i18n('contactDetails_phoneNumber_place')"
            data-cy="mobile"
            autocomplete="tel-national"
            input-validation
        />
        <span help-text i18n="@@contactDetails_phoneNumber_helpText" app-i18n="contactDetails_phoneNumber_helpText"></span>
        <div class="invalid-feedback" i18n="@@contactDetails_phoneNumber_errorText" app-i18n="contactDetails_phoneNumber_errorText"></div>
    </form-floating>

    <form-floating>
        <label i18n="@@contactDetails_communicationPreference_label" app-i18n="contactDetails_communicationPreference_label"></label>
        <select [formControl]="form.get('communicationPreference')" class="form-select">
            <option
                [ngValue]="null"
                i18n="@@contactDetails_communicationPreference_none"
                app-i18n="contactDetails_communicationPreference_none"
            ></option>
            <option
                value="email"
                i18n="@@contactDetails_communicationPreference_email"
                app-i18n="contactDetails_communicationPreference_email"
            ></option>
            <option
                value="phone"
                i18n="@@contactDetails_communicationPreference_phone"
                app-i18n="contactDetails_communicationPreference_phone"
            ></option>
        </select>
    </form-floating>
    <div class="row mb-3" *ngIf="formType !== 'call'">
        <div class="col-4">
            <label i18n="@@contactDetails_companyQuestion_label" app-i18n="contactDetails_companyQuestion_label"
                >Zakelijke verhuizing?</label
            >
        </div>
        <div class="col-8">
            <div class="form-check form-switch">
                <input [(ngModel)]="isCompanyRemoval" type="checkbox" class="form-check-input" data-cy="companyQuestion" />
                <label
                    class="form-check-label text-muted"
                    i18n="@@contactDetails_companyQuestion_helpText"
                    app-i18n="contactDetails_companyQuestion_helpText"
                    >Dit is optioneel</label
                >
            </div>
        </div>
    </div>

    <ng-container *ngIf="isCompanyRemoval">
        <form-floating>
            <label i18n="@@m3Info_accountManager_label" app-i18n="m3Info_accountManager_label"></label>
            <input
                class="form-control"
                [formControl]="form.get('accountManager.name')"
                type="text"
                placeholder="Naam accountmanager (optioneel)"
                i18n-placeholder="@@m3Info_accountManager_place"
                [attr.placeholder]="i18n('m3Info_accountManager_place')"
                data-cy="accountManager"
                autocomplete="off"
                input-validation
            />
        </form-floating>

        <div class="row">
            <div class="col-12 col-sm-6">
                <form-floating>
                    <label i18n="@@contactDetails_companyName_place" app-i18n="contactDetails_companyName_place"></label>
                    <input
                        class="form-control"
                        [formControl]="form.get('company.name')"
                        type="text"
                        placeholder="Bedrijfsnaam"
                        i18n-placeholder="@@contactDetails_companyName_place"
                        [attr.placeholder]="i18n('contactDetails_companyName_place')"
                        data-cy="nameCompany"
                        autocomplete="organization"
                        input-validation
                    />
                </form-floating>
            </div>
            <div class="col-12 col-sm-6">
                <form-floating>
                    <label i18n="@@contactDetails_kvk_label" app-i18n="contactDetails_kvk_label">kvk</label>
                    <input
                        class="form-control"
                        [formControl]="form.get('company.kvk')"
                        type="text"
                        placeholder="KVK-nummer"
                        i18n-placeholder="@@contactDetails_kvk_place"
                        [attr.placeholder]="i18n('contactDetails_kvk_place')"
                        data-cy="kvkCompany"
                        autocomplete="off"
                        input-validation
                    />
                </form-floating>
            </div>
        </div>
    </ng-container>
</ng-container>
