import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ReqOptions, RequestService } from './request.service'
import { get } from 'lodash'

@Injectable({
    providedIn: 'root',
})
export class I18nService {
    public ready = false
    public messagesSource = new BehaviorSubject<LocaleMessages>(null)
    public messages = this.messagesSource.asObservable()

    readonly idLocaleMap: IdLocaleMap = {
        nl: 'nl-NL',
        en: 'en-US',
    }
    readonly localeIdMap: LocaleIdMap = {
        'nl-NL': 'nl',
        'en-US': 'en',
    }

    private _locale: LocaleOption = 'nl-NL'
    public get locale() {
        return this._locale
    }
    public set locale(value) {
        if (value === this._locale) return
        this._locale = value
        this.useLocale(value)
    }

    public get languageId() {
        return this.localeIdMap[this.locale]
    }

    /** Offline data */
    private base: LocaleMessages
    private languageExtensions: MessageExtensions = {}

    constructor(private requestService: RequestService) {
        this.init()
    }

    private async init() {
        // Get default
        this.base = await this.fetchBase()
        const { locale } = this.base
        this.languageExtensions['base'] ??= {}
        this.languageExtensions['base'][locale] = this.base
        this.messagesSource.next(this.base)

        // Get from remote
        const res = await this.getLanguage().toPromise()
        const extension = { current: res.data }
        Object.assign(this.languageExtensions, extension)

        this.ready = true

        this.useLocale(this.locale)
    }

    async fetchBase() {
        return await fetch(`assets/locale/messages.json`).then((response) => {
            if (!response.ok) {
                throw new Error('HTTP error ' + response.status)
            }
            return response.json()
        })
    }

    /** Shortcut that handles concatenated keys */
    private getMessageFromExtension(locale: LocaleOption, key: string, extensionId: LanguageExtensionOption = 'current') {
        if (!key) return this.languageExtensions?.[extensionId]?.[locale]?.translations
        return get(this.languageExtensions?.[extensionId]?.[locale]?.translations, key)
    }

    /** Get translation with fallbacks */
    getMessage(key: string, locale = this.locale) {
        const message = this.getMessageFromExtension(locale, key)
        if (message || message === '') return message

        // Fallback on base, if not found in locale
        const messageBase = this.getMessageFromExtension(locale, key, 'base')
        if (messageBase || messageBase === '') {
            console.warn(`No message found for key: ${key} in locale: ${locale},
            falling back on base.`)
            return messageBase
        }

        console.error(`No message found for key: ${key} in locale: ${locale}`)
        return null
    }

    private useLocale(locale: LocaleOption) {
        // TODO: set angular locale
        // TODO: set ngx-datepicker locale
        this._locale = locale

        const messages = this.languageExtensions['current']?.[locale]
        if (messages) return this.messagesSource.next(messages)
        if (!this.ready) return console.warn('I18nService not ready yet')
        console.error(`No messages found for locale ${locale}`)
    }

    getLanguage(reqOpts: ReqOptions = null) {
        const url = `/api/language`
        return this.requestService.get(url, {}, reqOpts)
    }
}

export type LocaleShort = 'nl' | 'en'
export type LocaleOption = 'nl-NL' | 'en-US'
type IdLocaleMap = { [key in LocaleShort]: LocaleOption }
type LocaleIdMap = { [key in LocaleOption]: LocaleShort }
export type LanguageExtensionOption = 'current' | 'base'

interface LocaleMessages {
    locale: LocaleOption
    translations: { [key: string]: string }
}

type MessageExtensions = {
    [key in LanguageExtensionOption]?: { [key in LocaleOption]?: LocaleMessages }
}
