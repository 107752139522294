<div class="row" *ngIf="form && formEstimateAddresses" [formGroup]="form.get('estimate.addresses')" #rowsRoot>
    <div
        class="col-12 col-lg-6"
        *ngFor="let address of formEstimateAddresses.controls; let i = index; let last = last; let first = first"
        [formGroupName]="i"
    >
        <div class="card card-body mb-3">
            <h4 class="card-title d-flex justify-content-between">
                {{ getAddressString(i, first, last) }}

                <div class="d-flex flex-wrap justify-content-end gap-2">
                    <span class="btn-group btn-group-sm mb-2 ms-2">
                        <span dropdown>
                            <div class="btn btn-sm btn-outline-primary dropdown-toggle" dropdownToggle>
                                <span i18n="@@addressDetails_edit_dropdownLabel" app-i18n="addressDetails_edit_dropdownLabel"
                                    >Aanpassen</span
                                >
                                <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                                    <button
                                        class="dropdown-item"
                                        [disabled]="form.value.estimate.addresses.length <= 1 || first"
                                        (click)="permutateAddresses(i, i - 1, rowsRoot)"
                                    >
                                        <fa-icon [icon]="['fal', 'caret-up']" [fixedWidth]="true"></fa-icon>
                                        <span i18n="@@addressDetails_edit_moveUp" app-i18n="addressDetails_edit_moveUp">Naar voren</span>
                                    </button>
                                    <button
                                        class="dropdown-item"
                                        [disabled]="form.value.estimate.addresses.length <= 1 || last"
                                        (click)="permutateAddresses(i, i + 1, rowsRoot)"
                                    >
                                        <fa-icon [icon]="['fal', 'caret-down']" [fixedWidth]="true"></fa-icon>
                                        <span i18n="@@addressDetails_edit_moveDown" app-i18n="addressDetails_edit_moveDown"
                                            >Naar achteren</span
                                        >
                                    </button>

                                    <button
                                        class="dropdown-item"
                                        data-cy="addressRemove"
                                        [disabled]="form.value.estimate.addresses.length <= 1"
                                        (click)="removeAddress(i)"
                                    >
                                        <fa-icon [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
                                        <span i18n="@@addressDetails_edit_remove" app-i18n="addressDetails_edit_remove">Verwijder</span>
                                    </button>

                                    <button
                                        class="dropdown-item"
                                        [disabled]="form.value.estimate.addresses.length >= addressesMax"
                                        (click)="addAddress(i + 1)"
                                    >
                                        <fa-icon [icon]="['fal', 'plus']" [fixedWidth]="true"></fa-icon>
                                        <span i18n="@@addressDetails_edit_add" app-i18n="addressDetails_edit_add">Voeg adres toe</span>
                                    </button>
                                </ul>
                            </div>
                        </span>
                    </span>
                </div>
            </h4>

            <div class="form-group row" *ngIf="form.get('estimate.addresses.' + i).errors?.geocoder_status?.includes('ZERO_RESULTS')">
                <div class="col-12">
                    <!-- TODO: add this to language -->
                    <!-- <div class="alert alert-warning">
                        <h5 class="alert-heading">{{ language.addressDetails.error?.notFound?.title }}</h5>
                        <p class="mb-0">{{ language.addressDetails.error?.notFound?.body }}</p>
                    </div> -->
                </div>
            </div>

            <form-address
                [identifier]="identifier"
                [formControl]="address"
                [hasRemovalInfo]="hasRemovalInfo"
                (modalShown)="onModalShown()"
            ></form-address>
        </div>
    </div>
</div>
