<div class="row mt-4 text-center" #stepContainer>
    <h6
        [style.cursor]="isStepDisabled(step) ? 'not-allowed' : 'pointer'"
        class="col"
        *ngFor="let step of stepArray; let i = index"
        (click)="tryStep(step)"
        [class.opacity-50]="isStepDisabled(step)"
    >
        <span
            [class.bg-primary]="step === stepActive"
            [class.bg-secondary]="stepIndex > i"
            [class.border-secondary]="i > stepIndex"
            [class.text-reset]="i > stepIndex"
            class="step border"
            >{{ i + 1 }}
        </span>
        <span class="d-none d-sm-block" [app-i18n]="section + '_' + step + '_label'"></span>
    </h6>
    <div class="col-12">
        <progressbar [value]="progress"></progressbar>
    </div>
</div>
